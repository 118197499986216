import { LoaderFunctionArgs, MetaFunction } from "@remix-run/node";

export const meta: MetaFunction = () => {
  return [{ title: "Page not found — Three Words — workiversary.app" }];
};

export function loader({ request }: LoaderFunctionArgs) {
  if (!isHackerRequest(request.url)) {
    console.error(`Error: No route matches url "${request.url}"`);
  }
  return new Response("Not Found", {
    status: 404,
  });
}

export default function NotFoundPage() {
  return (
    <main>
      <h1>Page not found</h1>
      <p>We apologize — we couldn&rsquo;t find the webpage you were looking for.</p>
      <p>Try clicking on the link in the most recent email you&rsquo;ve received from us.</p>
      <p>
        If that doesn&rsquo;t work, email <a href="mailto:the.team@workiversary.com">the.team@workiversary.com</a> for
        assistance.
      </p>
    </main>
  );
}

function isHackerRequest(url: string): boolean {
  return (
    // folders...
    url.includes("/.git") ||
    url.includes("/.vscode") ||
    url.includes("/feed") ||
    url.includes("/wp-includes") ||
    url.includes("/static/admin") ||
    url.includes("/telescope/requests") ||
    url.includes("/debug") ||
    url.includes("/about") ||
    // extensions...
    url.includes(".php") ||
    url.includes(".env") ||
    url.includes(".config") ||
    url.includes(".tar.gz") ||
    url.includes(".sql") ||
    url.includes(".yaml") ||
    url.includes(".yml") ||
    url.includes(".zip") ||
    url.includes(".ssh") ||
    // XML files...
    url.includes("wlwmanifest.xml") ||
    url.includes("sitemap.xml") ||
    url.includes("config.xml") ||
    // other...
    url.includes("META-INF") ||
    url.includes("_all_dbs") ||
    url.includes(".DS_Store") ||
    url.includes("/server-status") ||
    url.includes("/login.action") ||
    url.includes("/config.json") ||
    url.includes("microsoft.exchange") ||
    url.includes("_catalog")
  );
}
